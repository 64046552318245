import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'

import { poststatuses } from '../../server/poststatus/statuses'
import globalRequestHelpers from '../../services/requests/helpers'

import wapplrcomponents from '../../../components'

const Wapplrgallery = wapplrcomponents.gallery

const GalleryContent = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const posts = this.getPosts()
        const postsdata = this.getPostsData()

        const posttype = this.getPostType()
        const posttypepath = this.getPostTypePath()
        const posttypename = this.getPostTypeName() || posttypepath

        return {
            isMounted: false,
            posts: posts || null,
            postsdata: postsdata || null,
            posttype: posttype || '',
            posttypepath: posttypepath || '',
            posttypename: posttypename || ''
        }
    },
    getHelpers: function() {

        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    componentDidMount: function() {

        const { searchData} = this.props
        this.historyHelpers = searchData.getHistoryHelpers()

        this.setState({
            isMounted: true
        })

    },
    componentWillUnmount: function() {
        this.setState({ isMounted: false })
    },

    getPostType: function() {
        const { helpers } = this.props
        const state = (helpers && helpers.store) ? helpers.store.getState() : null
        return state.query.posttype || ''
    },
    getPostTypePath: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].path) ? posttypesData[posttype].path : ''
    },
    getPostTypeName: function() {
        const { posttypesData = {} } = this.props
        const posttype = this.getPostType()
        return (posttype && posttypesData[posttype] && posttypesData[posttype].pluralname) ? posttypesData[posttype].pluralname : ''
    },
    getGlobalState: function() {
        const { state, teststate = null } = this.props
        return (state) ? state : teststate
    },
    getPosts: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.archive && state.response.archive.posts) ? state.response.archive.posts : null
    },
    getPostsData: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.archive && state.response.archive.data) ? state.response.archive.data : null
    },
    getParent: function() {
        const parentE = this.refElements['parent']
        const div = this.getElement(parentE)
        return (div && div.parentElement) ? div.parentElement : null
    },
    setRef: function(a, e) {
        if (a && e) {
            const { setRef } = this.props
            this.refElements[a] = e
            if (setRef) setRef(a, e)
        }
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },

    //Title
    getTitle: function() {

        const {
            route,
            routes = {},
            curlang = {}
        } = this.props

        const {
            userpostssubtitle = 'Posts',
            userpoststrashsubtitle = 'Deleted posts',
            userimagesssubtitle = 'Images',
            userimagesstrashsubtitle = 'Deleted images'
        } = curlang

        const {
            wapplruserapppostsslug = '/posts',
            wapplruserappimagesslug = '/images'
        } = routes

        let userpoststitle = 'User posts'

        if (route === wapplruserapppostsslug) userpoststitle = userpostssubtitle
        if (route === wapplruserapppostsslug + '/trash') userpoststitle = userpoststrashsubtitle
        if (route === wapplruserappimagesslug) userpoststitle = userimagesssubtitle
        if (route === wapplruserappimagesslug + '/trash') userpoststitle = userimagesstrashsubtitle

        return userpoststitle
    },
    render: function() {

        const { searchData } = this.props
        const props = this.props
        const state = this.state
        const setRef = this.setRef
        const getParent = this.getParent

        const globalstate = this.getGlobalState()
        const title = this.getTitle()


        const historyHelpers = searchData.getHistoryHelpers()

        const input = {
            ...props,
            ...state,
            historyHelpers,
            title,
            globalstate,
            setRef,
            getParent
        }

        return (
            <div ref={function(e) {
                setRef('parent', e)
            }}>
                <Wapplrgallery {...input} />
            </div>
        )
    }
})


export default createReactClass({
    getInitialState: function() {
        const { user } = this.props
        return {
            query: {
                posttype: 'image',
                user: user.id
            },
            response: {
                archive: {
                    posts: null,
                    data: null
                }
            },
            ready: false,
            isMounted: false
        }
    },
    setResponse: function({ name, value }) {
        if (!this.state.ready && this.state.isMounted) {
            this.setState({
                response: {
                    [name]: value
                },
                ready: true
            })
        }
        const state = this.state
        return { state: state, action: name, value: value }
    },
    getState: function() {
        return this.state
    },
    getHelpers: function() {
        const { fetch } = this.props
        const getState = this.getState
        const setResponse = this.setResponse

        return {
            fetch: fetch,
            store: {
                getState: getState,
                dispatch: function() {
                }
            },
            states: {
                actions: {
                    setResponse: setResponse
                }
            }
        }
    },
    componentDidMount: function() {
        const props = this.getConstruct()
        const { getFetch } = props
        this.setState({
            isMounted: true
        })
        getFetch({ state: this.state })
    },
    componentWillUnmount: function() {
        this.state.isMounted = false
        this.setState({
            isMounted: false
        })
    },
    getHelpersData: function({ helpers }) {

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getFetch: async function({ helpers, fetchreq, req }) {

        let resp = null
        let respData = null
        const { fetch, store, state, setResponse } = this.getHelpersData({ helpers })

        if (fetchreq && fetch) {

            const getBody = (typeof fetchreq.data.body == 'function' || typeof fetchreq.data.getBody == 'function') ?
                (typeof fetchreq.data.body == 'function') ? fetchreq.data.body : fetchreq.data.getBody
                : null

            fetchreq.data.body = (getBody) ? getBody({ state, req }) : fetchreq.data.body
            fetchreq.data.getBody = getBody

            if (req && req.headers && req.headers.cookie) fetchreq.data.headers.cookie = req.headers.cookie
            resp = await fetch(fetchreq.path, fetchreq.data)
            if (resp.status !== 200) throw new Error(resp.statusText)
            respData = (resp && resp.json) ? await resp.json() : null

            if (respData.data) respData = respData.data

            if (Object.keys(respData).length) {
                Object.keys(respData).map(function(rk) {
                    const reqname = rk || 'unnamed'
                    if (respData && store && setResponse) {
                        store.dispatch(setResponse({
                            name: reqname,
                            value: respData[reqname] || respData
                        }))
                    }
                })
            }

            return respData

        }

    },
    getFetchreq: function() {

        return {
            path: '/graphql',
            data: {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: function({ state }) {

                    const page = (state && state.query && state.query.page) ? state.query.page : 0
                    const user = (state && state.query && state.query.user) ? state.query.user : 0

                    let q = {
                        t: '',
                        c: {},
                        p: page,
                        u: user,
                        pt: 'Image',
                        sgt: (poststatuses.created - 1)
                    }

                    q = JSON.stringify(q).replace(/"/g, '\'')

                    const postQuery = globalRequestHelpers.defaultPostQuery()
                    const postsDataQuery = globalRequestHelpers.defaultPostsDataQuery()
                    const type = 'archive'

                    return JSON.stringify({
                        query: `{` +
                            type + `(query:"${q}") {
                            posts{
                                ${postQuery}
                            }
                            data{
                                ${postsDataQuery}
                            }
                        }
                    }`
                    })
                },
                credentials: 'include'
            }
        }

    },
    getConstruct: function() {

        const { req } = this.props

        const props = this.props
        const helpers = this.getHelpers()
        const route = '/images'
        const state = this.state
        const getfetch = this.getFetch
        const fetchreq = this.getFetchreq()

        const getFetch = async function({ state }) {
            return await getfetch({ helpers, fetchreq, req, state })
        }

        return {
            ...props,
            helpers,
            route,
            state,
            getFetch
        }
    },
    render: function() {
        const state = this.state
        const props = (state.ready) ? this.getConstruct() : {}
        return (state.ready) ? <GalleryContent {...props} /> : null
    }
})


