import React from 'react'

import wapplrcomponents from '../../../components'
import gs from '../../../themes/components/Wapplrgallery.css'

import Usergallery from './index'
import Wapplrsnackbar from '../Wapplrsnackbar'
import Uploadbutton from '../Uploadbutton'
import us from '../Uploadbutton/Uploadbutton.css'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import FloatingActionButton from 'material-ui/FloatingActionButton'

import ContentAdd from 'material-ui/svg-icons/content/add'
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever'

const Wapplrgallery = wapplrcomponents.galleryformsy

export default function({
                            fetchs,
                            curlang,
                            searchData,
                            posttypesData,
                            history,
                            routes,
                            uploadconfig,
                            config = {},
                            getthumb,
                            style
                        }) {

    const {
        formname = 'gallery',
        validations = {
            maxLength: 1
        },
        validationErrors = {
            maxLength: curlang.maxcharerror(1)
        },
        value = function(state, props) {
            const { user} = props
            return (user && user.avatarrel) ? [user.avatarrel] : []
        },
        getSelectedPosts = function({ user}) {
            return (user && user.avatarrel) ? [user.avatarrel] : []
        },
        afterSelect = function({ e, selectedposts, setPosts, onChange, user}) {
            const avatar = (selectedposts && selectedposts.length) ? selectedposts[selectedposts.length - 1] : null
            if (avatar) {
                user.avatarrel = avatar
                user.avatar = avatar.cover
                user.avatarprops = null
                setPosts({ posts: [avatar], modalclose: true })
                if (onChange) onChange(e, [avatar])
            } else {
                user.avatarrel = null
                user.avatar = null
                user.avatarprops = null
                setPosts({ posts: [], modalclose: true })
                if (onChange) onChange(e, [])
            }
        },
        afterUpload = function({ response, setPosts, onChange, user}) {

            let avatar = null

            if (response) {
                if (response.length) {
                    let wassucc = false
                    response.map(function(i) {
                        if (i.success) {
                            avatar = i
                            if (i.post) avatar = i.post
                            wassucc = true
                        }
                    })
                } else {
                    if (response.success) {
                        avatar = response
                        if (response.post) avatar = response.post
                    }
                }
            }

            if (avatar) {
                user.avatarrel = avatar
                user.avatar = avatar.cover
                user.avatarprops = null
                setPosts({ posts: [avatar], modalclose: true })
                if (onChange) onChange(null, [avatar])
            }

        },
        deleteFunction = function({ newposts, user}) {
            const avatar = (newposts && newposts.length) ? newposts[newposts.length - 1] : null
            if (avatar) {
                user.avatarrel = avatar
                user.avatar = avatar.cover
                user.avatarprops = null
            } else {
                user.avatarrel = null
                user.avatar = null
                user.avatarprops = null
            }
        },
        selectsimple = true,
        title = '',
        disableupload = function() {
            return false
        },
        editor,
        disablebodyoverflowhidden,
        data = {}
    } = config

    let tempClassName = ''

    const addClassToBody = function(bodyClass) {
        if (typeof window !== 'undefined' && !disablebodyoverflowhidden && bodyClass) {
            if (!tempClassName) {
                tempClassName = window.document.body.className
            }
            window.document.body.className = window.document.body.className + ' ' + bodyClass
        }
    }

    const removeClassFromBody = function() {
        if (typeof window !== 'undefined') {
            if (tempClassName || tempClassName === '') {
                window.document.body.className = tempClassName
                tempClassName = null
            }
        }
    }

    return {
        type: 'upload',
        component: Wapplrgallery,
        style: style,
        attr: {
            name: formname,
            value: value,
            editor: editor,
            curlang: curlang,
            style: gs,
            getthumb: function() {
                return getthumb
            },
            helpers: function(state, props) {
                const { helpers } = props
                return helpers
            },
            validations: validations,
            validationErrors: validationErrors,
            modal: function(state, props) {
                const { user, post, fetch} = props

                const du = disableupload({ state, props, user, post })

                return function(p = {}) {

                    const selectedposts = getSelectedPosts({ user, post })

                    const { state, modalclose, style, setRef, refElements, setPosts, galleryprops } = p

                    const { onChange } = galleryprops

                    const actions = [
                        <FlatButton
                            label={curlang.canceltext}
                            onTouchTap={(...attributes) => {
                                removeClassFromBody()
                                modalclose(...attributes)
                            }}
                        />,
                        <FlatButton
                            label={curlang.submittext}
                            keyboardFocused={true}
                            onTouchTap={function(e) {
                                const usergallery = refElements['wapplrgallery']
                                const selectedposts = (usergallery && usergallery.dataCurrentToPostsArray) ? usergallery.dataCurrentToPostsArray() : []
                                afterSelect({ e, selectedposts, setPosts, onChange, user, post })
                                removeClassFromBody()
                            }}
                        />
                    ]

                    function plusbutton(props) {

                        const {
                            saveimagessuccessmessage = 'All image were uploaded',
                            saveimagesdefaultfail = function({ length, hmsuccess }) {
                                return 'Sended images:' + length + ', success uploaded:' + hmsuccess
                            }
                        } = curlang

                        const galleryRefs = p.refElements

                        const refElements = {}

                        function setRef(a, e) {
                            if (a && e) {
                                refElements[a] = e
                            }
                        }

                        function onchangeinput(e) {
                            const uploadbutton = refElements['uploadbutton']
                            const processingStart = uploadbutton.setProcess
                            const processingEnd = uploadbutton.endProcess
                            const resetForm = function() {
                            }
                            const invalidateForm = function() {
                            }
                            savepost({ ...data }, e, resetForm, invalidateForm, { processingStart, processingEnd })
                        }

                        const savefetch = fetchs.newimage({
                            getHelpers: function() {
                                const { fetch } = props
                                return { fetch, state: {} }
                            },
                            curlang: curlang,
                            uploadconfig: uploadconfig,
                            multiple: function({ response, processingEnd }) {

                                let waitTime = 4000

                                if (response.length) {

                                    let hmsuccess = 0
                                    response.map(function(r) {
                                        if (r.success) hmsuccess = hmsuccess + 1
                                    })

                                    const message = (response.length === hmsuccess) ? saveimagessuccessmessage : saveimagesdefaultfail({
                                        length: response.length,
                                        hmsuccess
                                    })
                                    snack(message)
                                    processingEnd()

                                    if (response.length === hmsuccess) {
                                        waitTime = 1500
                                        setTimeout(function() {
                                            processingEnd({ reset: true })
                                        }, waitTime)
                                    } else {
                                    }

                                } else {


                                    const message = response.message || 'savepostdefaultfail'
                                    snack(message)
                                    setTimeout(function() {
                                        processingEnd({ reset: true })
                                    }, 1500)
                                }

                                const usergallery = galleryRefs['wapplrgallery']
                                const selectedposts = (usergallery && usergallery.dataCurrentToPostsArray) ? usergallery.dataCurrentToPostsArray() : []

                                removeClassFromBody()

                                setTimeout(function() {
                                    afterUpload({ response, setPosts, onChange, user, post, selectedposts })
                                }, waitTime)

                            }
                        })

                        function snack(message) {
                            const nodemessage = (message) ? <div>{message}</div> : <div />
                            const snack = refElements['snack']
                            if (snack && snack.open) snack.open(nodemessage)
                        }

                        function savepost(data, event, resetForm, invalidateForm, refs) {
                            data.id = 1
                            savefetch(data, event, resetForm, invalidateForm, refs)
                        }

                        const uploadtext = curlang.uploadtext

                        const {
                            enableuploadtypes
                        } = uploadconfig

                        let enableattr = 'image/*'
                        if (enableuploadtypes && enableuploadtypes.length) {
                            enableattr = ''
                            enableuploadtypes.map(function(a) {
                                enableattr = (!enableattr) ? 'image/' + a : enableattr + ',image/' + a
                            })
                        }

                        return (
                            <div>
                                <Uploadbutton style={us} setRef={setRef} uploadText={uploadtext} inputprops={{
                                    onChange: onchangeinput,
                                    multiple: true,
                                    accept: enableattr
                                }} />
                                <Wapplrsnackbar ref={function(e) {
                                    setRef('snack', e)
                                }} />
                            </div>
                        )
                    }

                    return (
                        <Dialog
                            ref={(setRef) ? function(e) {
                                setRef('dialog', e)
                            } : null}
                            title={curlang.selectimage}
                            modal={false}
                            actions={actions}
                            open={state.modalopen}
                            onRequestClose={(...attributes) => {
                                removeClassFromBody()
                                modalclose(...attributes)
                            }}
                            autoScrollBodyContent={true}
                            className={style.gallerymodal}
                        >
                            <Usergallery
                                setRef={setRef}
                                style={gs}
                                searchData={searchData}
                                curlang={curlang}
                                posttypesData={posttypesData}
                                routes={routes}
                                history={history}
                                fetch={fetch}
                                user={user}
                                post={post}
                                disablehref={true}
                                selectable={true}
                                selectsimple={selectsimple}
                                type={'gallery'}
                                selectedposts={selectedposts}
                                plusbutton={(!du) ? plusbutton : null}
                                getthumb={getthumb}
                                feedprops={{
                                    clickIsSelect: true
                                }}
                            />
                        </Dialog>
                    )
                }
            },
            plusbutton: function(state, props) {
                const { style } = props
                return function(props) {

                    function openusergallery(e, a) {
                        const { modalopen} = a
                        addClassToBody(style.bodyStyleDisableOverflow)
                        modalopen()
                    }

                    return (
                        <FloatingActionButton
                            onTouchTap={function(e) {
                                openusergallery(e, props)
                            }}
                            onTouchStart={function() {

                            }}
                        >
                            <ContentAdd />
                        </FloatingActionButton>
                    )
                }
            },
            deleteicon: function(state, props) {
                return function({ post, style, galleryprops }) {
                    const { onChange, posts } = galleryprops

                    const editpost = props.post
                    const user = props.user

                    function deletefunction(e, { post }) {
                        const newposts = []
                        if (posts && posts.length) {
                            posts.map(function(p) {
                                if (p.id && post.id && post.id !== p.id) {
                                    newposts.push(p)
                                } else if (p.cover && post.cover && post.cover !== p.cover) {
                                    newposts.push(p)
                                } else if (p.avatar && post.avatar && post.avatar !== p.cover) {
                                    newposts.push(p)
                                }
                            })
                        }
                        deleteFunction({ newposts, deletepost: post, user, post: editpost })
                        if (onChange) onChange(e, newposts)
                    }

                    return (
                        <IconButton
                            onTouchTap={function(e) {
                                deletefunction(e, { post })
                            }}
                            onTouchStart={function() {

                            }}
                            className={style.cardrightbuttonicon}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )
                }
            },
            type: 'gallery',
            disablehref: true,
            title: title,
            ...{ history, searchData: searchData, posttypesData: posttypesData }
        }

    }

}
